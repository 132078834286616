import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({ darkMode, toggleDarkMode }) => {
  return (
    <header className="bg-white dark:bg-gray-800 shadow-md">
      <div className="container mx-auto px-4 py-4 flex items-center">
        <Link to="/" className="text-2xl font-bold text-gray-800 dark:text-white mr-6">
          gio.fyi
        </Link>
        <nav className="flex-grow">
          <ul className="flex items-center">
            {/* Removed the Content & Media link */}
          </ul>
        </nav>
        <button
          onClick={toggleDarkMode}
          className="text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-white ml-4"
        >
          {darkMode ? '☀️' : '🌙'}
        </button>
      </div>
    </header>
  );
};

export default Header;

