import React from 'react';
import { Link } from 'react-router-dom';

const LinkButton = ({ href, children, external }) => {
  const baseClass = "block w-full px-6 py-4 mb-3 text-lg font-medium text-center rounded-xl bg-gray-800 hover:bg-gray-700 text-white dark:bg-gray-700 dark:hover:bg-gray-600 transition-all duration-200";
  
  if (external) {
    return (
      <a 
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={baseClass}
      >
        {children}
      </a>
    );
  }
  
  return (
    <Link to={href} className={baseClass}>
      {children}
    </Link>
  );
};

const HomePage = () => {
  return (
    <div className="max-w-3xl mx-auto px-4 py-12 text-center">
      {/* Profile Section */}
      <div className="mb-12">
        <div className="w-32 h-32 mx-auto mb-8 rounded-full bg-gray-200 dark:bg-gray-700 overflow-hidden">
          <img
            src="/images/profile.jpeg"
            alt="Giorgio Zinetti"
            className="w-full h-full object-cover"
          />
        </div>
        <h1 className="text-3xl font-bold mb-3">Giorgio Zinetti</h1>
        <div className="text-lg text-gray-400 dark:text-gray-500 mb-6">
          <span className="font-medium">CTO</span> <span className="text-gray-500">@</span><span className="text-blue-400">CardanoFoundation</span>
          <span className="mx-2">•</span>
          <span className="font-medium">Board Member</span> <span className="text-gray-500">@</span><span className="text-green-400">Saipient</span>
          <span className="mx-2">•</span>
          <span className="font-medium">Angel Investor</span>
        </div>
        <p className="text-base text-gray-500 dark:text-gray-400 max-w-2xl mx-auto">
          I'm actively looking for ambitious startups to invest in, advise, or help scale,
          <br className="hidden md:inline" />
          particularly in <span className="font-medium">AI</span>, <span className="font-medium">blockchain</span>, and <span className="font-medium">health and longevity tech</span>.
        </p>
      </div>

      {/* Investment & Advisory Links */}
      <div className="max-w-md mx-auto space-y-3 mb-8">
        <LinkButton href="/work-with-me">
          Why Work With Me
        </LinkButton>

        <LinkButton href="https://zcal.co/giojzi/15min" external>
          ☕ Schedule a Chat (€5)
        </LinkButton>
      </div>

      {/* Social Links */}
      <div className="mb-8">
        <p className="text-base text-gray-500 dark:text-gray-400 mb-4">
          Follow me for updates:
        </p>
        <div className="max-w-md mx-auto space-y-3">
          <LinkButton href="https://x.com/giozzi" external>
            𝕏 Twitter
          </LinkButton>
          
          <LinkButton href="https://www.linkedin.com/in/giorgio-zinetti-51423753" external>
            LinkedIn
          </LinkButton>

          <LinkButton href="https://www.instagram.com/giojzi/" external>
            Instagram
          </LinkButton>
        </div>
      </div>

      {/* Quote */}
      <p className="mt-12 text-sm italic text-gray-400 dark:text-gray-500">
        "What do you care what other people think?"
      </p>
    </div>
  );
};

export default HomePage;